import initReCAPTCHAs from "../../core/reCAPTCHA";

export default function initReportMemorial(props) {
  document
    .querySelectorAll(`[name="${props.reasonCheckboxesName}"]`)
    .forEach((checkbox) => {
      const alert = document.querySelector(`[data-show-when-checked="${checkbox.id}"]`);
      if (alert) {
        checkbox.addEventListener("change", () => {
          alert.classList.toggle("d-none", !checkbox.checked);
        });

        alert.classList.toggle("d-none", !checkbox.checked);
      }
    });

  window.initReCAPTCHAs = initReCAPTCHAs;
}
